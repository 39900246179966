import React from 'react';
import { Button, Modal, ModalScrollableContainer, Textarea, Toolbar } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';


type DemoMessageDealerModalProps = {
    isVisible: boolean,
    onClose?(): void,
};

const DemoMessageDealerModal = (props: DemoMessageDealerModalProps) => {

    const { isVisible, onClose } = props;

    return (
        <Modal
            title="Message ‘Dealer Name’"
            isVisible={isVisible}
            size="S"
            onClose={onClose}
            dataUI={getDataUI()}
        >
            <Toolbar className="mb-m" dataUI={getDataUI()} leftSide={(
                <>
                    <Button className="mr-s" icon="fal fa-paper-plane" dataUI={getDataUI()}>Send Message</Button>
                    <Button btnType="secondary" icon="far fa-times-circle" onClick={onClose} dataUI={getDataUI()}>Cancel</Button>
                </>
            )}>
            </Toolbar>
            {/* <ModalScrollableContainer> */}
            <Textarea
                dataUI={getDataUI()}
                isFullHeight
                name="textarea"
                rows={7}
                placeholder="Type your message here..."
            />
            {/* </ModalScrollableContainer> */}
        </Modal>
    );
};

export default DemoMessageDealerModal;