import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { QueryItem, QueryBuilder } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const INITIAL_ITEMS: QueryItem[] = [
    {
        id: 1,
        title: 'File Credit Grade <> 1 ',
        type: 'condition',
        combinator: 'AND',
        testSuccess: true
    },
    {
        id: 2,
        title: 'Last Review date between 8/23/2022 and 8/31/2022 ',
        type: 'condition',
        combinator: 'AND',
        testSuccess: false
    },
    {
        id: 3,
        type: 'group',
        children: [
            {
                id: 4,
                title: 'File Credit Grade <> 4',
                type: 'condition',
                combinator: 'AND',
                testSuccess: false
            }
        ],
        combinator: 'AND',
        testSuccess: true
    },
];


const QueryBuilderPage: React.FC = () => {
    const [items, setItems] = useState(INITIAL_ITEMS);

    return (
        <div>
            <h1 className="mb-s">QueryBuilder</h1>
            <p className="mb-l">The QueryBuilder serves to generate groups and conditions for various queries in
                a drag-n-drop tree with nesting. Reordering is handled internally, while adding, removing and updating
                processes are exposed for external logic. <br />
                Groups and conditions inside the QueryBuilder are constructed through nested "QueryItem" objects. Details
                are provided in the API Table. <br />
                Under the hood, the QueryBuilder uses <a href="https://github.com/SortableJS/react-sortablejs" target="_blank" rel="noopener noreferrer">React bindings for SortableJS</a>.
            </p>

            <p className="mb-xl">
                <strong style={{ color: 'var(--color-warning)' }}>IMPORTANT:</strong> Due to
                incompatibility <a href="https://github.com/SortableJS/react-sortablejs/issues/263" target="_blank" rel="noopener noreferrer">issues</a> of React-SortableJS with React 18,
                it is currently required to utilize <a href="https://react.dev/reference/react-dom/flushSync" target="_blank" rel="noopener noreferrer">flushSync</a>, in order to make
                the QueryBuilder state management bindigs work.
            </p>


            <p className="mb-l">The demo below is oversimplified version of the QueryBuilder. For a fully-fledged
                example of the component visit <Link to="/components/business-rules" target="_blank">Business Rules demo page</Link>.</p>


            <div className="mb-m">
                <QueryBuilder
                    dataUI={getDataUI()}
                    items={items}
                    onItemsReorder={(reorderedItems) => {
                        flushSync(() => setItems(reorderedItems));
                    }}
                    onAddCondition={(groupId) => console.log(`Should add a new condition in group: ${groupId}`)}
                    onAddGroup={(groupId) => console.log(`Should add a new group inside group: ${groupId}`)}
                    onCombinatorChange={(itemId) => console.log(`Should update the combinator of: ${itemId}`)}
                    onDelete={(itemId) => console.log(`Should delete item: ${itemId}`)}
                    onEditCondition={(conditionId) => console.log(`Should edit: ${conditionId}`)}
                    onDuplicate={(itemId) => console.log(`Should duplicate: ${itemId}`)}
                    sortableProps={{
                        onEnd: (event) => {
                            console.log('Dragging has ended.', event);
                        }
                    }}
                />
            </div>


            <p className="mb-xl">
                <strong>Important: </strong>The <i>"id"</i> of each item should be unique for
                the QueryBuilder tree to work correctly.
            </p>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import { QueryItem, QueryBuilder } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const INITIAL_ITEMS: QueryItem[] = [
    {
        id: 1,
        title: 'File Credit Grade <> 1 ',
        type: 'condition',
        combinator: 'AND',
        testSuccess: true
    },
    {
        id: 2,
        title: 'Last Review date between 8/23/2022 and 8/31/2022 ',
        type: 'condition',
        combinator: 'AND',
        testSuccess: false
    },
    {
        id: 3,
        type: 'group',
        children: [
            {
                id: 4,
                title: 'File Credit Grade <> 4',
                type: 'condition',
                combinator: 'AND',
                testSuccess: false
            }
        ],
        combinator: 'AND',
        testSuccess: true
    },
];

const Example: React.FC = (props) => {
    const [items, setItems] = useState(INITIAL_ITEMS);
 
    return (
        <QueryBuilder
            dataUI={getDataUI()}
            items={items}
            onItemsReorder={(reorderedItems) => {
                // NOTE: After React-SortableJS is fixed, the flushSync might become obsolete.
                flushSync(() => setItems(reorderedItems));
            }}
            onAddCondition={(groupId) => console.log(\`Should add a new condition in group: \${groupId}\`)}
            onAddGroup={(groupId) => console.log(\`Should add a new group inside group: \${groupId}\`)}
            onCombinatorChange={(itemId) => console.log(\`Should update the combinator of: \${itemId}\`)}
            onDelete={(itemId) => console.log(\`Should delete item: \${itemId}\`)}
            onEditCondition={(conditionId) => console.log(\`Should edit: \${conditionId}\`)}
            onDuplicate={(itemId) => console.log(\`Should duplicate: \${itemId}\`)}
            sortableProps={{
                onEnd:(event)=>{
                    console.log('Dragging has ended.', event);
                }
            }}
        />
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>items</td>
                        <td>
                            {'QueryItem[]'}
                            <br />
                            {'QueryItem = {'}
                            <br />
                            <div className="pl-xs">
                                id: number,
                                <br />
                                title?: string,
                                <br />
                                type: 'group' | 'condition',
                                <br />
                                children?: QueryItem[],
                                <br />
                                combinator: 'OR' | 'AND' (QueryCombinator),
                                <br />
                                testSuccess?: boolean | null | undefined
                            </div>
                            <br />
                            {'}'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Array of objects to build the QueryBuilder tree with.
                        </td>
                    </tr>
                    <tr>
                        <td>onItemsReorder</td>
                        <td>
                            {'(newState: QueryItem[] | ((prevState: QueryItem[]) => QueryItem[])) => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Dispatcher function for changes on the tree order of the QueryBuilder. It is advised to use
                            "useState" hook to provide and manage the items, which means that the state setter function
                            will serve best for this prop.
                        </td>
                    </tr>
                    <tr>
                        <td>onCombinatorChange</td>
                        <td>
                            {'(itemId: number, newValue: QueryCombinator) => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Emits after change of the combinator value for the given item.
                        </td>
                    </tr>
                    <tr>
                        <td>onAddGroup</td>
                        <td>
                            {'(groupId: number) => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Receives handler function that should update the tree by adding a new group inside
                            the given group.
                        </td>
                    </tr>
                    <tr>
                        <td>onAddCondition</td>
                        <td>
                            {'(groupId: number) => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Receives handler function that should update the tree by adding a new condition inside
                            the given group.
                        </td>
                    </tr>
                    <tr>
                        <td>onDelete</td>
                        <td>
                            {'(itemId: number) => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Emits to indicate that the given item should be deleted, as well as the sub-tree below it, if it
                            is a group.
                        </td>
                    </tr>
                    <tr>
                        <td>onEditCondition</td>
                        <td>
                            {'(conditionId: number) => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Emits to indicate that the given condition should be edited.
                        </td>
                    </tr>
                    <tr>
                        <td>onDuplicate</td>
                        <td>
                            {'(itemId: number) => void'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Emits to indicate that the given item should be copied and added right
                            after its current position.
                            <br />
                            <strong>Note:</strong> The copied block should have different id for each of its items.
                        </td>
                    </tr>
                    <tr>
                        <td>sortableProps</td>
                        <td>
                            <a href="https://github.com/SortableJS/Sortable#options" target="_blank" rel="noopener noreferrer">SortableOptions</a>
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Options for furher configuration of the underlying sortable component.
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default QueryBuilderPage;
